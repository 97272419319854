<template>
  
  <v-card>
    
    <v-card-text>
      <v-col>
        <p class="text-h5">{{$t("Updates")}}</p>
      </v-col>
      <v-col>
        <v-row>
          <v-col cols="12">
            <v-btn
              color="#196619"
              dark
              @click="proceedUpdate"
              :loading="loading"
            >
              {{$t('update')}}
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-textarea
              height="600px"
              :label="$t('Output')"
              :value="textoutput"
              outlined
            >
              
            </v-textarea>
          </v-col>
        </v-row>
      </v-col>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
    name: "updates",

    data() {
      return {
        loading: false,
        textoutput: '',
        stream: null,

      }
    },
                  
    methods: {
      proceedUpdate() {
         try {
          this.textoutput = ''
          this.loading = true
          this.stream = new EventSource("/api/v2/updates")
          this.stream.addEventListener("message", (f) => {
              console.log(f.data);
              this.textoutput += f.data 
          })
          this.stream.addEventListener("exit", (f) => {
              console.log(f.data);
              this.textoutput += f.data
              this.stream.close(); 
              this.loading = false
          })

        
        } catch (e) {
          this.$emit('alertMsg', { type: 'error', text: e.message +"\n"})
          console.log(e)
          this.loading = false
        }
      }
    }

    




}
</script>

<style>

</style>